import React, { useEffect, useCallback, useMemo } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import styles from './general-infos.module.scss';

import { useGetEstimate, useUpdateEstimate, useGetEstimatesList } from '~/hooks/estimates';

import {
  InputText,
  InputNumber,
  StyledSelect,
  InputFormatPattern,
  InputEmail,
  ErrorField,
} from '~/lib/HooksFormFields';
import { IPricing } from '~/types/agencies';
import PreviewField from '~/components/PreviewField';
import { IOption } from '~/types/options';

const requiredRule = { required: 'Ce champ est requis' };

const GeneralInfos = ({ isPreview }: { isPreview: boolean }) => {
  const { id } = useParams();
  const {
    data: estimate = {
      agency: null,
      logistics: null,
      status: 'in-progress',
    },
  } = useGetEstimate(id || '');

  const { data: list = {} } = useGetEstimatesList();
  const {
    departments, discount, worksite, agencies,
  } = list;

  const operationalBases = useMemo(() => {
    const agency = agencies?.find((a: any) => a.value === estimate?.agency?._id);
    if (agency) {
      return agency.pricing
        .sort((a:IPricing, b: IPricing) => a.location.localeCompare(b.location))
        .map((a: IPricing) => ({ label: a.location, value: a.location, ...a }));
    }
    return [];
  }, [agencies, estimate]);

  const {
    mutate: updateEstimate,
  } = useUpdateEstimate('Les modifications ont été prise en compte');

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...estimate,
    },
  });
  const errorsForm: any = errors;
  const form : any = watch();

  const operationalBasePrice = useMemo(() => {
    const prices : any = {

    };
    const base = operationalBases?.find(
      (o: any) => o.value === form?.logistics?.operationalBase,
    );
    if (base?.hourlyRate) {
      prices.hourlyRate = base?.hourlyRate;
    }
    const transportType = form?.transport?.trucks.type;
    const maxSize = form?.transport?.trucks.maxSize;
    if (base?.trucks && transportType) {
      const transportPrice6m = base?.trucks.find((t: any) => {
        const { key } = t;
        if (key === 'PL-6M' && transportType === 'sans grue') {
          return t;
        }
        if (key === 'PL-6MG' && transportType === 'avec grue') {
          return t;
        }
        return null;
      })?.price;
      if (transportPrice6m) {
        prices.truck6m = transportPrice6m;
      }
      const transportPrice12m = base?.trucks.find((t: any) => {
        const { key } = t;
        if (key === 'PL-13M' && transportType === 'sans grue') {
          return t;
        }
        if (key === 'PL-12-MG' && transportType === 'avec grue') {
          return t;
        }
        return null;
      })?.price;
      if (transportPrice12m && maxSize === '12m') {
        prices.truck12m = transportPrice12m;
      }
    }
    return prices;
  }, [form, operationalBases]);

  const submit = useCallback(async (data: FieldValues) => {
    updateEstimate({
      _id: id,
      ...data,
    });
  }, [estimate]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <div className={styles.generalInfos}>
      <section>
        <h4>Information sur le client</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                label='Nom du chantier'
                value={form?.worksite?.name}
              />
              : <InputText
                name='worksite.name'
                label='Nom du chantier'
                rules={requiredRule}
                control={control}
              />
            }
            {errorsForm.worksite?.name.message
              && <ErrorField message={errorsForm.worksite?.name.message} />}
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Ville'
                value={form?.worksite?.city}
              />
            : <InputText
              name='worksite.city'
              label='Ville'
              control={control}
            />
          }
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Adresse'
                value={form?.worksite?.address}
              />
            : <InputText
              name='worksite.address'
              label='Adresse'
              control={control}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Date démarrage prévisionnelle'
                value={form?.worksite?.estimatedStartDate ? format(new Date(), 'dd/MM/yyyy') : '---'}
              />
            : <InputText
              name='worksite.estimatedStartDate'
              type='date'
              label='Date démarrage prévisionnelle'
              control={control}
            />
          }
          </div>
        </div>
        {isPreview
          ? <PreviewField
                label="Complément d'adresse"
                value={form?.worksite?.address2}
              />
          : <InputText
              name='worksite.address2'
              label="Complément d'adresse"
              control={control}
            />
          }
      </section>
      <hr />
      <section>
        <h4>Contact côté client</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Nom'
                value={form?.contact?.lastName}
              />
            : <InputText
              name='contact.lastName'
              label='Nom'
              rules={requiredRule}
              control={control}
            />
          }
           {errorsForm?.contact?.lastName?.message
            && <ErrorField message={errorsForm?.contact?.lastName.message} />}
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Prénom'
                value={form?.contact?.firstName}
              />
            : <InputText
              name='contact.firstName'
              label='Prénom'
              rules={requiredRule}
              control={control}
            />
          }
          {errorsForm?.contact?.firstName?.message
            && <ErrorField message={errorsForm?.contact?.firstName?.message} />}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label="Téléphone"
                value={form?.contact?.phone}
              />
            : <InputFormatPattern
              name="contact.phone"
              label="Téléphone"
              control={control}
              format="## ## ## ## ##"
              rules={{
                pattern: {
                  value: /^(?:\s*\d){10}$/,
                  message: "Le champs de numéro de téléphone n'est pas valide",
                },
              }}
            />
            }
            {errorsForm?.contact?.phone?.message
            && <ErrorField message={errorsForm?.contact?.phone.message} />}
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label="Adresse mail"
                value={form?.contact?.email}
              />
            : <InputEmail
              name="contact.email"
              control={control}
              label="Adresse mail"
              placeholder='adressemail@mail.fr'
              required
            />
            }
            {errorsForm?.contact?.email?.message
            && <ErrorField message={errorsForm?.contact?.email.message} />}
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h4>Informations sur le chantier</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Département'
                value={departments?.find((d: IOption) => d.value === form?.worksite?.department)?.label || '---'}
              />
            : <StyledSelect
              name='worksite.department'
              label='Département'
              control={control}
              options={departments}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Matériel utilisé'
                value={worksite?.materialsUsed?.find((d: IOption) => d.value === form?.worksite?.materialUsed)?.label || '---'}
              />
            : <StyledSelect
              name='worksite.materialUsed'
              label='Matériel utilisé'
              control={control}
              isClearable
              options={worksite?.materialsUsed || []}
            />
          }
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Charge'
                value={worksite?.loads?.find((d: IOption) => d.value === form?.worksite?.load)?.label || '---'}
              />
            : <StyledSelect
              name='worksite.load'
              label='Charge'
              control={control}
              isClearable
              options={worksite?.loads || []}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Stabilité'
                value={worksite?.stabilities?.find((d: IOption) => d.value === form?.worksite?.stability)?.label || '---'}
              />
            : <StyledSelect
              name='worksite.stability'
              label='Stabilité'
              control={control}
              isClearable
              options={worksite?.stabilities || []}
            />
          }
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                  label='Moyens Accès'
                  value={worksite?.access?.find((d: IOption) => d.value === form?.worksite?.access)?.label || '---'}
                />
              : <StyledSelect
                name='worksite.access'
                label='Moyens Accès'
                control={control}
                isClearable
                options={worksite?.access || []}
              />
            }
          </div>
          <div className={`${styles.containerField} ${styles.duration}`}>
          {isPreview
            ? <PreviewField
                label='Durée global du chantier'
                value={form?.worksite?.duration ? `${form?.worksite?.duration} mois` : '---'}
            />
            : <>
              <label>Durée global du chantier</label>
              <div className={styles.fields}>
                <InputNumber
                    name='worksite.duration'
                    control={control}
                  />
              </div>
              </>
            }
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h4>Informations logistique</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
            {isPreview
              ? <PreviewField
                  label='Base opérationnelle'
                  value={operationalBases?.find((d: IOption) => d.value === form?.logistics?.operationalBase)?.label || '---'}
                />
              : <>
            <StyledSelect
              name='logistics.operationalBase'
              label='Base opérationnelle'
              control={control}
              isSearchable
              options={operationalBases}
            />
            {form?.logistics?.operationalBase && !operationalBasePrice?.hourlyRate && <ErrorField message="la base opérationnelle est incompléte" />}
            </>
          }
          </div>
          {operationalBasePrice?.hourlyRate
            && <p className={styles.rate}>
              Taux horaire : <span>{operationalBasePrice?.hourlyRate} €</span>
            </p>
          }
        </div>
        <div className={styles.row}>
          <div className={`${styles.containerField} ${!isPreview ? styles.discount : ''}`}>
          {isPreview
            ? <PreviewField
                label='Remise sur la MO'
                className='discount'
                value={form?.logistics?.discountMO ? `${form.logistics.discountMO * 100} %` : '---'}
            />
            : <StyledSelect
              name='logistics.discountMO'
              label='Remise sur la MO'
              control={control}
              className={'discount'}
              options={discount?.workforce}
            />
          }
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h4>Location</h4>
        <div className={styles.row}>
          <div className={`${styles.containerField} ${!isPreview ? styles.discount : ''}`}>
          {isPreview
            ? <PreviewField
                label='Remise sur la location'
                className='discount'
                value={form?.location?.discount ? `${form.location.discount * 100} %` : '---'}
            />
            : <StyledSelect
              name='location.discount'
              label='Remise sur la location'
              control={control}
              className={'discount'}
              options={discount?.location}
            />
            }
          </div>
        </div>
      </section>
      <hr />
      <section>
        <h4>Transport</h4>
        <div className={styles.row}>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Taille maximale des camions'
                value={worksite?.trucksMaxSizes?.find((d: IOption) => d.value === form?.transport?.trucks?.maxSize)?.label || '---'}
              />
            : <StyledSelect
              name='transport.trucks.maxSize'
              label='Taille maximale des camions'
              placeholder='Taille maximale des camions'
              control={control}
              options={worksite?.trucksMaxSizes || []}
            />
          }
          </div>
          <div className={styles.containerField}>
          {isPreview
            ? <PreviewField
                label='Type de camion'
                value={worksite?.trucksTypes?.find((d: IOption) => d.value === form?.transport?.trucks?.type)?.label || '---'}
              />
            : <StyledSelect
              name='transport.trucks.type'
              label='Type de camion'
              placeholder='Type de camion'
              control={control}
              options={worksite?.trucksTypes || []}
            />
          }
          </div>
        </div>
        <div className={styles.row}>
          <div className={`${styles.containerField} ${!isPreview ? styles.discount : ''}`}>
            {isPreview
              ? <PreviewField
                  label='Remise sur les transports'
                  className='discount'
                  value={form?.transport?.discount ? `${form.transport.discount * 100} %` : '---'}
              />
              : <StyledSelect
                name='transport.discount'
                label='Remise sur les transports'
                control={control}
                className={'discount'}
                options={discount?.transport}
              />
            }
          </div>
        </div>
        {operationalBasePrice?.truck6m
          && <p className={styles.rate}>
            Taux transport 6m : <span>{operationalBasePrice.truck6m} €</span>
          </p>
        }
        {operationalBasePrice?.truck12m
          && <p className={styles.rate}>
            Taux transport 12m : <span>{operationalBasePrice.truck12m} €</span>
          </p>
        }
      </section>
    </div>
  );
};

export default GeneralInfos;
